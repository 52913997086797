/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    ol: "ol",
    li: "li",
    p: "p",
    ul: "ul"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Steps:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Copy the text below"), "\n", React.createElement(_components.li, null, "Paste it into ", React.createElement("a", {
    href: "https://www.hemingwayapp.com",
    target: "_blank"
  }, "hemingwayapp.com")), "\n", React.createElement(_components.li, null, "Edit to improve the readability score"), "\n"), "\n", React.createElement(_components.h3, null, "Text to be revised:"), "\n", React.createElement(_components.p, null, "To complete this technical writing assignment, you will need to increase the readability score of this whole, entire page.\nYou might want to try breaking up sentences into smaller chunks, or removing pedantic words, or putting lists together from long, meandering sentences."), "\n", React.createElement(_components.p, null, "Below are a few sentences I've encountered from clients while I edit their documentation:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "In a multi-tenant deployment, where each financial institution is a tenant, the 'sub' field in this token must be unique to the user in each tenant."), "\n", React.createElement(_components.li, null, "This documentation approach enables your organization to more easily integrate your Capstone API implementation with other data networks"), "\n", React.createElement(_components.li, null, "Create an account on the dashboard, navigate to Team Settings > Keys and provide the client_id listed there to your integration support contact."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
